import React from 'react'

const HellesAlkoholfreiSvg = (props, fill = '#004580') => (
  <svg
    width={144}
    height={64}
    fill="none"
    viewBox="0 0 301.2 98.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill={ fill }>
      <path class="st0" d="M51.6,89.3v.4h-9.9v-.4c.7-1.3,1.1-2.4,1.1-3.3v-17.1c0-1.4-.3-3-1-4.9-.7-1.9-1.7-3.7-3-5.5-1.3-1.7-2.9-3.1-4.7-4l-.8,2.8c-1.7,6.2-3.2,11.4-4.6,15.5,1.7-.5,3.1-1.2,4.2-2.1,1.1-.9,1.9-1.9,2.4-2.8.5-.9.8-1.6.8-2.1l.2-.2c.6.2,1.1.6,1.4,1.3.4.7.6,1.6.6,2.6s-.3,2.3-1,3.8c-.7,1.5-1.9,2.9-3.7,4.3-1.8,1.3-4.3,2.1-7.5,2.3-2.3,5.7-4.9,10.1-7.7,13.2-2.8,3.2-6.2,4.7-10.2,4.7-2.7,0-4.8-.8-6.2-2.3-1.4-1.5-2.1-3.3-2.1-5.3s.2-2,.6-2.8c.4-.9.9-1.5,1.5-1.9l.4-.2c0,.7,0,1.5.2,2.5.2.9.8,1.8,1.6,2.6s2,1.2,3.6,1.2c2.1,0,4-1.1,5.6-3.3,1.6-2.2,3.2-5.3,4.8-9.5-3-1.2-5.2-3.1-6.9-5.8-1.6-2.7-2.5-5.6-2.5-8.8s.7-5.7,2.1-8.3c1.4-2.6,3.6-4.7,6.5-6.4,2.9-1.7,6.5-2.5,10.7-2.6,2-5.3,4.2-9.6,6.5-13,2.3-3.4,5-5.6,8-6.6,1.2-.4,2.5-.6,3.8-.6h5.6s0,.4,0,.4c-.5.5-.9,1.1-1.3,2-.3.9-.5,1.7-.5,2.5v54.1c0,1,.4,2.1,1.2,3.4ZM18.4,57.4c-1.8,2.1-2.7,4.4-2.7,7s.4,3.4,1.2,4.8c.8,1.5,2,2.6,3.5,3.4,2.4-8.3,4.3-14.7,5.7-19-3.4.5-5.9,1.8-7.7,3.8ZM42.8,53.6v-18.7c-1.3,1.1-2.5,2.9-3.6,5.1-1.1,2.3-2.2,5.1-3.3,8.5,2.6,1.1,4.9,2.8,6.9,5.1Z"/>
      <path class="st0" d="M64.7,37.6v.4c-.7,1.2-1.1,2.3-1.1,3.2v31.5h6.5c.6,0,1.1-.1,1.6-.4.5-.3,1.4-.8,2.7-1.8h.2v8.5h-19.4v-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-2-1-3.2v-.4h9.4Z"/>
      <path class="st0" d="M95.8,79.1l-6.6-20.6-2.8,4.5v12.5c0,.9.3,1.9,1,3.2v.4h-9.3v-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-1.9-1-3.2v-.4h9.3v.4c-.7,1.2-1,2.3-1,3.2v10.3l6.6-10.9c.4-.6.6-1.2.6-1.7s-.1-.6-.3-.9v-.4h9.6v.3c-.9.9-1.7,1.9-2.2,2.7l-6.6,10.3,8.6,25.6c.2.6.7,1.4,1.6,2.3v.3h-8.4Z"/>
      <path class="st0" d="M105.3,47.1c1.2-3.2,2.9-5.6,5.1-7.4,2.2-1.7,4.7-2.6,7.5-2.6s5.3.9,7.5,2.6c2.2,1.8,3.8,4.2,5,7.5,1.2,3.2,1.8,6.9,1.8,11.1s-.6,7.8-1.8,11c-1.2,3.2-2.9,5.7-5,7.4-2.2,1.8-4.7,2.6-7.5,2.6-2.8,0-5.3-.9-7.5-2.7-2.2-1.8-3.9-4.3-5.1-7.5-1.2-3.2-1.8-7-1.8-11.2s.6-7.8,1.8-11ZM122.3,69.1c1.1-2.7,1.7-6.2,1.7-10.5s-.6-8.3-1.7-11.1c-1.1-2.8-2.6-4.2-4.4-4.2-1.2,0-2.3.6-3.2,1.8-.9,1.2-1.7,3-2.2,5.2-.5,2.2-.8,4.8-.8,7.7s.3,5.6.8,7.9c.5,2.3,1.2,4.1,2.2,5.3.9,1.3,2,1.9,3.2,1.9,1.8,0,3.3-1.3,4.4-4Z"/>
      <path class="st0" d="M160.1,37.6v.4c-.4.7-.6,1.2-.8,1.6-.2.4-.2.8-.2,1.3v34.9c0,.9.3,1.9,1,2.9v.4h-9.4s0-.4,0-.4c.7-1.3,1.1-2.3,1.1-3.2v-15.7h-7.6s0,15.7,0,15.7c0,.5,0,1,.3,1.5.2.5.5,1,.8,1.7v.4h-9.8v-.4c.7-1.3,1.1-2.3,1.1-3.2v-34.3c0-.9-.4-1.9-1.1-3.2v-.4h9.8v.4c-.7,1.3-1.1,2.3-1.1,3.2v12.3h7.6s0-12.3,0-12.3c0-.9-.4-1.9-1.1-3.2v-.4h9.4Z"/>
      <path class="st0" d="M165.3,47.1c1.2-3.2,2.9-5.6,5.1-7.4,2.2-1.7,4.7-2.6,7.5-2.6,2.8,0,5.3.9,7.5,2.6,2.2,1.8,3.8,4.2,5,7.5,1.2,3.2,1.8,6.9,1.8,11.1s-.6,7.8-1.8,11c-1.2,3.2-2.9,5.7-5,7.4-2.2,1.8-4.7,2.6-7.5,2.6-2.8,0-5.3-.9-7.5-2.7s-3.9-4.3-5.1-7.5c-1.2-3.2-1.8-7-1.8-11.2s.6-7.8,1.8-11ZM182.3,69.1c1.1-2.7,1.7-6.2,1.7-10.5s-.6-8.3-1.7-11.1c-1.1-2.8-2.6-4.2-4.4-4.2-1.2,0-2.3.6-3.2,1.8-.9,1.2-1.7,3-2.2,5.2-.5,2.2-.8,4.8-.8,7.7s.3,5.6.8,7.9c.5,2.3,1.2,4.1,2.2,5.3.9,1.3,2,1.9,3.2,1.9,1.8,0,3.3-1.3,4.4-4Z"/>
      <path class="st0" d="M205,37.6v.4c-.7,1.2-1.1,2.3-1.1,3.2v31.5h6.5c.6,0,1.1-.1,1.6-.4.5-.3,1.4-.8,2.7-1.8h.2v8.5h-19.4v-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-2-1-3.2v-.4h9.4Z"/>
      <path class="st0" d="M233.8,43.5c-.6-.2-1.2-.3-1.8-.3h-5.5s0,11.1,0,11.1h2.8c1,0,2.2-.4,3.6-1.1h.6s0,7.6,0,7.6h-.6c-1.5-.7-2.7-1.1-3.6-1.1h-2.8s0,15.7,0,15.7c0,1,.4,2.1,1.3,3.2v.4h-9.6v-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-2-1-3.2v-.4h18.4v7h-.2c-1.1-.5-2-.9-2.6-1.1Z"/>
      <path class="st0" d="M256.5,79.1l-5.5-17.3h-2.1v13.7c0,.9.3,2,1,3.2v.4h-9.3s0-.4,0-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-2-1-3.2v-.4h11.9c3.3,0,6,1.1,8,3.2,2,2.1,3,4.9,3,8.4s-.6,5.2-1.9,7.3c-1.3,2.1-2.8,3.6-4.6,4.5l5.7,14.6c.4,1.1,1.1,2.1,1.8,3v.4h-8.1ZM254.5,54.2c1-1.1,1.5-2.5,1.5-4.3,0-3.9-1.9-5.8-5.8-5.8h-1.2s0,11.7,0,11.7h2.1c1.3,0,2.5-.5,3.5-1.6Z"/>
      <path class="st0" d="M282.2,43.5h-5.9v10.2h2.8c.9,0,2.2-.3,3.6-1h.5v7.6h-.5c-1.5-.7-2.7-1-3.6-1h-2.8s0,13.9,0,13.9h6.9c.6,0,1.2-.1,1.8-.4.6-.2,1.4-.6,2.6-1.2h.2s0,7.5,0,7.5h-19.8s0-.4,0-.4c.7-1.2,1-2.3,1-3.2v-34.3c0-.9-.3-2-1-3.2v-.4h18.8s0,7.4,0,7.4h-.2c-2-.9-3.4-1.4-4.4-1.4Z"/>
      <path class="st0" d="M301.2,37.6v.4c-.7,1.3-1.1,2.3-1.1,3.2v34.3c0,.5,0,1,.3,1.5.2.5.5,1,.8,1.7v.4h-9.4v-.4c.7-1.3,1.1-2.3,1.1-3.2v-34.3c0-.5,0-1-.3-1.5-.2-.5-.5-1-.8-1.7v-.4h9.4Z"/>
    </g>
    <g fill={ fill }>
      <path class="st0" d="M122.9.3v.3c-.2.4-.4.8-.5,1.1-.1.3-.2.6-.2.9v23.3c0,.6.2,1.3.7,1.9v.3h-6.3v-.3c.5-.8.7-1.6.7-2.1v-10.5h-5.1s0,10.5,0,10.5c0,.3,0,.7.2,1,.1.3.3.7.6,1.2v.3h-6.5s0-.3,0-.3c.5-.8.7-1.6.7-2.1V2.7c0-.6-.2-1.3-.7-2.1v-.3h6.5s0,.3,0,.3c-.5.8-.7,1.6-.7,2.1v8.2h5.1s0-8.2,0-8.2c0-.6-.2-1.3-.7-2.1v-.3h6.3Z"/>
      <path class="st0" d="M136,4.2h-3.9s0,6.8,0,6.8h1.9c.6,0,1.4-.2,2.4-.7h.3s0,5.1,0,5.1h-.3c-1-.4-1.8-.7-2.4-.7h-1.9s0,9.3,0,9.3h4.6c.4,0,.8,0,1.2-.2.4-.2,1-.4,1.7-.8h.2s0,5,0,5h-13.2s0-.3,0-.3c.5-.8.7-1.5.7-2.1V2.7c0-.6-.2-1.3-.7-2.1v-.3h12.6s0,4.9,0,4.9h-.2c-1.3-.6-2.3-1-2.9-1Z"/>
      <path class="st0" d="M149.6.3v.3c-.5.8-.7,1.5-.7,2.1v21h4.4c.4,0,.7,0,1.1-.3.3-.2.9-.6,1.8-1.2h.2s0,5.7,0,5.7h-12.9s0-.3,0-.3c.5-.8.7-1.5.7-2.1V2.7c0-.6-.2-1.3-.7-2.1v-.3h6.3Z"/>
      <path class="st0" d="M165.6.3v.3c-.5.8-.7,1.5-.7,2.1v21h4.4c.4,0,.7,0,1.1-.3.3-.2.9-.6,1.8-1.2h.2v5.7h-12.9v-.3c.5-.8.7-1.5.7-2.1V2.7c0-.6-.2-1.3-.7-2.1v-.3h6.3Z"/>
      <path class="st0" d="M184.8,4.2h-3.9v6.8h1.9c.6,0,1.4-.2,2.4-.7h.3s0,5.1,0,5.1h-.3c-1-.4-1.8-.7-2.4-.7h-1.9v9.3h4.6c.4,0,.8,0,1.2-.2s1-.4,1.7-.8h.2s0,5,0,5h-13.2v-.3c.5-.8.7-1.5.7-2.1V2.7c0-.6-.2-1.3-.7-2.1v-.3h12.6v4.9h-.2c-1.3-.6-2.3-1-2.9-1Z"/>
      <path class="st0" d="M193.6,23.8c.6.5,1.5.8,2.5.8.9,0,1.6-.3,2.2-1,.6-.6.9-1.5.9-2.6s-.3-2.1-1-3.2c-.7-1.1-1.7-2.4-3.1-3.8-1.3-1.3-2.2-2.6-2.8-3.9-.6-1.3-.9-2.6-.9-4.1s.6-3.2,1.9-4.3C194.6.5,196.3,0,198.5,0c1,0,1.8,0,2.5.2.7.1,1.1.3,1.3.4l.2,5.5h-.3c-.4-.6-.9-1.1-1.5-1.6-.6-.5-1.3-.7-2.1-.7-.6,0-1,.2-1.4.7-.4.4-.5.9-.5,1.5,0,1.4.6,2.7,1.8,4,1.9,1.9,3.3,3.8,4.2,5.5.9,1.8,1.4,3.5,1.4,5.3s-.7,4.1-2.1,5.4c-1.4,1.4-3.2,2.1-5.5,2.1-1.2,0-2.2-.1-3-.4s-1.3-.6-1.6-1v-5.3c-.1,0,.2,0,.2,0,.4.9,1,1.7,1.6,2.2Z"/>
    </g>
  </svg>
)

export default HellesAlkoholfreiSvg
